import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/Home.scss'
import package1img from '../../assets/images/package-img.png'
import { useQuery, useQueryClient } from 'react-query'
import { getPackageList } from '../../query/package/package.query'
import { useLocation, useNavigate } from 'react-router-dom'
import { getGamesFromPackage } from '../../query/package/gamesPackage.query'
import Spinner from '../../components/Spinner'

const Packages = () => {
	const { state } = useLocation()
	const [id, setId] = useState(state?.id)
	const [gameName, setGameName] = useState(state?.gameName)

	const navigate = useNavigate()
	window.history.replaceState({}, id)
	const token = localStorage.getItem('Token')

	const [packageList, setPackageList] = useState([])
	const [stateGameList, setStateGameList] = useState([])
	const [unLockedGames, setUnlockedGames] = useState([])
	const [lockedGames, setLockedGames] = useState([])

	const handleNavigate = (id, packageName) => {
		navigate('/spel', { state: { id, packageName } })
	}

	const queryClient = useQueryClient()

	const { isFetching } = useQuery('package', () => getPackageList(), {
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setPackageList(response)
		},
		onError: (error) => {
			setPackageList([])
		},
	})

	const getFilterdList = id !== undefined

	const { isFetching: filterdLoading } = useQuery(['PackageGames', id], () => getGamesFromPackage(id), {
		enabled: getFilterdList,
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setStateGameList(response)
		},
	})

	useEffect(() => {
		if (id) {
			const filterdData = packageList?.filter((data) => {
				return stateGameList?.find((d) => d?._id === data?._id)
			})
			const filteredId = filterdData?.map((data) => {
				return data?._id
			})
			const matched = packageList.filter((obj1) => filteredId.some((obj2) => obj1._id === obj2))

			const unMatched = []
			for (let i = 0; i < packageList.length; i++) {
				const match = filteredId.find((item) => item === packageList[i]._id)
				if (!match) {
					unMatched.push(packageList[i])
				}
			}

			setUnlockedGames(matched)
			setLockedGames(unMatched)
		}
	}, [id, stateGameList, packageList])

	useEffect(() => {
		if (!id) {
			setUnlockedGames([])
			setLockedGames([])
		}
	}, [id])

	useEffect(() => {
		token && navigate('/')
	}, [token])

	function handleRemoveFilter() {
		setUnlockedGames([])
		setLockedGames([])
		setId()
		setGameName()
		queryClient.invalidateQueries('package')
	}

	return (
		<>
			<Header isActive={'package'} />
			<div className='package-details-sec package-list-sec'>
				<div className='container'>
					<div className='text-center'>
						<h2 className='common-title red'>Paket</h2>
					</div>
					{!!id && !!lockedGames?.length && (
						<>
							<div className='my-games-header-sec'>
								<h4>{gameName}</h4>
								<a
									className='view-more-btn'
									onClick={handleRemoveFilter}>
									View All Packages
								</a>
							</div>
							<p className='lock-info'>Note:- The "Grey" packages are not available in this package.</p>
						</>
					)}
					{isFetching || filterdLoading ? (
						<div className='w-100 d-flex justify-content-center'>
							<Spinner />
						</div>
					) : (
						<ul className='packages-list'>
							{!!unLockedGames?.length &&
								unLockedGames?.map((item, index) => {
									return (
										<li
											key={index}
											onClick={() => handleNavigate(item?._id, item?.sName)}>
											<h4>{item?.sName}</h4>
											<div className='packaimg-icon'>
												<img src={package1img} />
											</div>
											<ul className='package-info-list'>
												<li>1 Admin </li>
												<li>{item?.nAllowedMember} konton (lärarkonton+elevkonton)</li>
											</ul>
											<a className='view-details-btn'>Visa spelinfo</a>
											<a className='pricing-btn'>
												{item?.nPrice} kr/år ({item?.nAllowDays} dagar)
											</a>
										</li>
									)
								})}
							{!!lockedGames?.length &&
								lockedGames?.map((item, index) => {
									return (
										<li
											key={index}
											className='lock-item'>
											<h4>{item?.sName}</h4>
											<div className='packaimg-icon'>
												<img src={package1img} />
											</div>
											<ul className='package-info-list'>
												<li>1 Admin </li>
												<li>{item?.nAllowedMember} konton (lärarkonton+elevkonton)</li>
											</ul>
											<a className='view-details-btn'>Visa spelinfo</a>
											<a className='pricing-btn'>
												{item?.nPrice} kr/år ({item?.nAllowDays} dagar)
											</a>
										</li>
									)
								})}
							{isFetching ? (
								<div className='w-100 d-flex justify-content-center'>
									<Spinner />
								</div>
							) : (
								!id &&
								packageList?.map((item, index) => {
									return (
										<li
											key={index}
											onClick={() => handleNavigate(item?._id, item?.sName)}>
											<h4>{item?.sName}</h4>
											<div className='packaimg-icon'>
												<img src={package1img} />
											</div>
											<ul className='package-info-list'>
												<li>1 Admin </li>
												<li>{item?.nAllowedMember} konton (lärarkonton+elevkonton)</li>
											</ul>
											<a className='view-details-btn'>Visa spelinfo</a>
											<a className='pricing-btn'>
												{item?.nPrice} kr/år ({item?.nAllowDays} dagar)
											</a>
										</li>
									)
								})
							)}
						</ul>
					)}
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Packages
