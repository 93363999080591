import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/Contact.scss'
import { useQuery } from 'react-query'
import { getProfile } from '../../query/login/login.mutation'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
	const token = localStorage.getItem('Token')
	const navigate = useNavigate()
	const [profileData, setProfileData] = useState()

	useEffect(() => {
		if (!token) {
			navigate('/')
		}
	}, [token])

	useQuery(['getProfile'], () => getProfile(token), {
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setProfileData(response)
		},
	})

	return (
		<>
			<Header />

			<>
				<div className='contact-us-sec'>
					<div className='container'>
						<div className='row'>
							<div className='offset-md-3 col-md-6'>
								<div className='contact-form-sec'>
									<div className='form-grp'>
										<span>
											<h5 style={{ color: '#fd5555' }}>Name</h5>
										</span>
										<input
											disabled
											className='mb-3'
											value={profileData?.sFullName || '-'}
										/>
										<span>
											<h5 style={{ color: '#fd5555' }}>Email</h5>
										</span>
										<input
											value={profileData?.sEmail || '-'}
											disabled
											className='mb-3'
										/>
										<span>
											<h5 style={{ color: '#fd5555' }}>Class</h5>
										</span>
										<input
											disabled
											className='mb-3'
											value={profileData?.sClass?.sClassName || '-'}
										/>
										<span>
											<h5 style={{ color: '#fd5555' }}>School Name</h5>
										</span>
										<input
											disabled
											value={profileData?.sSchool?.sSchoolName || '-'}
											className='mb-3'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		</>
	)
}
export default Profile
