const dev = {
	URL: 'http://3.73.130.67:3030/api/v1/',
	s3: 'https://runtolearn-assets-dev.s3.eu-central-1.amazonaws.com/',
	Admin_Panel_URL: 'http://run-to-learn-admin-panel-dev.s3-website.eu-central-1.amazonaws.com/login',
}

const stag = {
	URL: 'http://52.28.101.104:3030/api/v1/',
	s3: 'https://runtolearn-assets-stag.s3.eu-central-1.amazonaws.com/',
	Admin_Panel_URL: 'http://run-to-learn-admin-panel-stag.s3-website.eu-central-1.amazonaws.com/login',
}

const prod = {
	URL: 'https://api.thinkfirst.se/api/v1/',
	s3: 'https://runtolearn-assets-prod.s3.eu-central-1.amazonaws.com/',
	Admin_Panel_URL: 'https://login.thinkfirst.se/login',
}

export default prod
