import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import CloseButton from '../assets/images/close-icon.png'
import Slider from 'react-slick'
import { useQuery } from 'react-query'
import { getGameInfoById } from '../query/game-info/gameinfo.query'
import { useNavigate } from 'react-router-dom'
import Spinner from './Spinner'
import config from '../config'

export default function Gamemodal({ modal, togglemodal, gameId }) {
	const token = localStorage.getItem('Token')
	const [gameInfo, setGameInfo] = useState({ sGameDescription: '' })
	const [videoLinks, setVideoLinks] = useState()
	const [imageLinks, setImageLinks] = useState()
	const { isLoading } = useQuery(['gameInfo', gameId], () => getGameInfoById(gameId), {
		select: (data) => data.data.data,
		onSuccess: (response) => {
			setGameInfo(response)
			setVideoLinks(response?.aMedia.filter((item) => item.endsWith('.mp4')))
			setImageLinks(response?.aMedia.filter((item) => item.endsWith('.jpeg') || item.endsWith('.jpg')))
		},
		onError: () => {
			setGameInfo({})
		},
	})

	const navigate = useNavigate()
	const handleNavigate = (id, gameName) => {
		navigate('/paket', { state: { id, gameName } })
	}

	const gameinfoslider = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	function convertNewlinesToBreaks(str) {
		return str.replace(/\n/g, '</br>')
	}

	const convertedStr = convertNewlinesToBreaks(gameInfo?.sGameDescription)
	return (
		<Modal
			toggle={togglemodal}
			className='gameinfo-popup'
			id='gameinfo-popup'
			isOpen={modal}>
			<ModalBody>
				{isLoading ? (
					<div className='w-100 d-flex justify-content-center'>
						<Spinner />
					</div>
				) : (
					<>
						<div className='common-modal-sec'>
							<button
								onClick={togglemodal}
								className='common-close-btn'>
								<img
									src={CloseButton}
									alt=''
								/>
							</button>
							<h2>{gameInfo?.sGameName}</h2>
							<div className='game-info-sec'>
								<div className='game-info-left-sec'>
									<img
										src={config?.s3 + gameInfo?.sGameIcon}
										alt='game-icon'
									/>
									<ul>
										<li>
											<label>Ämnen som omfattas:</label>
											<span>{gameInfo?.sSubjectCovered}</span>
										</li>
										<li>
											<label>Lämplig för:</label>
											<span>{gameInfo?.sSuitableFor}</span>
										</li>
										<li>
											<label>Anpassningsbar:</label>
											<span>{gameInfo?.sCustomizable}</span>
										</li>
									</ul>
								</div>
								<div className='game-info-right-sec'>
									<div className='game-info-desc'>
										{/* <p>{gameInfo?.sGameDescription}</p> */}
										<div
											style={{ whiteSpace: 'pre-line' }}
											dangerouslySetInnerHTML={{ __html: convertedStr }}
										/>
									</div>
									{!token && (
										<div className='text-right'>
											<a
												onClick={() => handleNavigate(gameInfo?._id, gameInfo?.sGameName)}
												className='package-info-link'>
												Paketinformation...
											</a>
										</div>
									)}
								</div>
							</div>
							<div className='game-info-slider-sec'>
								<h4>Mediagalleri</h4>
								{imageLinks?.length + videoLinks?.length > 2 ? (
									<Slider
										{...gameinfoslider}
										className='game-info-slider'>
										{imageLinks?.map((media, index) => {
											return (
												<div key={index}>
													<div className='gameinfoslide'>
														<img src={config?.s3 + media} />
													</div>
												</div>
											)
										})}
										{videoLinks?.map((media, index) => {
											return (
												<div
													className='gameinfoslide'
													key={index}>
													<video
														width='100%'
														controls>
														<source
															src={config?.s3 + encodeURIComponent(media)}
															type='video/mp4'
														/>
													</video>
												</div>
											)
										})}
									</Slider>
								) : (
									<div style={{ display: 'flex' }}>
										<div>
											{imageLinks?.map((media, index) => {
												return (
													<div key={index}>
														<div className='gameinfoslide'>
															<img src={config?.s3 + media} />
														</div>
													</div>
												)
											})}
										</div>
										<div>
											{videoLinks?.map((media, index) => {
												return (
													<div
														className='gameinfoslide'
														key={index}>
														<video
															width='100%'
															controls>
															<source
																src={config?.s3 + encodeURIComponent(media)}
																type='video/mp4'
															/>
														</video>
													</div>
												)
											})}
										</div>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</ModalBody>
		</Modal>
	)
}
