import axios from 'axios'
import config from './config'

let nav
export function setNav(n) {
  nav = n
}


const instance = axios.create({
  baseURL: config.URL
})

function navigationTo(link) {
  nav(link)
}

function removeToken() {
  localStorage.clear('')
  sessionStorage.clear('')
}


instance.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if ((err?.response && err?.response?.status === 404 || err?.response?.status === 419|| err?.response?.status === 401) ) {
      removeToken()
      navigationTo({ to: '/login', replace: true })
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)


export default instance
