import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Slider from 'react-slick'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/Home.scss'
import package1img from '../../assets/images/package-img.png'
import { getFeatureList } from '../../query/feature/feature.query'
import { useMutation, useQuery } from 'react-query'
import axios, { setNav } from '../../axios'
import { getPackageList } from '../../query/package/package.query'
import playnowbtn from '../../assets/images/Spela-Nu.png'
import { getGameList } from '../../query/game/game.query'
import Gamemodal from '../../components/Gamemodal'
import { useNavigate } from 'react-router-dom'
import { getProfile, login } from '../../query/login/login.mutation'
import { toast } from 'react-toastify'
import PrivateGameModal from '../../components/PrivateGameModal'
import eyeimg from '../../assets/images/eye-icon.png'
import eyeimgactive from '../../assets/images/eye-icon-active.png'
import Spinner from '../../components/Spinner'
import config from '../../../src/config'
import { Col, Modal, ModalHeader, Row } from 'reactstrap'
import SignUp from '../../components/SignUp'

const Home = () => {
	const mailRegex =
		/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const [featureImages, setFeatureImages] = useState([])
	const [featureDesc, setFeatureDesc] = useState('')
	const [bannerImage, setBannerImage] = useState()
	const [packageList, setPackageList] = useState([])
	const [firstFour, setFirstFour] = useState([])
	const [firstFourGame, setFirstFourGame] = useState([])
	const [packageLength, setPackageLength] = useState(0)
	const [gameList, setGameList] = useState([])
	const [gameLength, setGameLength] = useState(0)
	const [email, setEmail] = useState()
	const [password, setPassword] = useState()
	const [errEmail, setErrEmail] = useState()
	const [privateModal, setPrivateModal] = useState(false)
	const [togglePassword, setTogglePassword] = useState(false)
	const [youtubeVideo, setYoutubeVideo] = useState()
	const [signUpModal, setSignUpModal] = useState(false)

	const [modal, setModal] = useState(false)
	const [gameInfoModal, setGameInfoModal] = useState(false)
	const [gameId, setGameId] = useState()

	const token = localStorage.getItem('Token')

	const togglemodal = (id) => {
		return setModal(!modal)
	}

	const togglePrivateModal = () => {
		return setPrivateModal(!privateModal)
	}
	const toggleGameModal = (id) => {
		setGameId(id)
		return setGameInfoModal(!gameInfoModal)
	}

	const { data, isLoading } = useQuery('feature', () => getFeatureList(), {
		select: (data) => data?.data?.data[0],
		onSuccess: (response) => {
			setFeatureImages(response?.aFeatureImage)
			setYoutubeVideo(response?.sYoutubeVideoLink)
			setFeatureDesc(response?.sFeatureDescription)
		},
		onError: () => {
			setFeatureImages([])
			setFeatureDesc('')
		},
	})

	const { data: dataBanner, isFetching: fetchingBanner } = useQuery('banner', () => axios.get(`web/cms/bannerImage`), {
		select: (data) => data?.data?.data[0],
		onSuccess: (response) => {
			setBannerImage(config?.s3 + response?.sBannerImage)
		},
		onError: () => {
			setBannerImage('')
		},
	})

	useQuery('package', () => getPackageList(), {
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setPackageLength(response?.length)
			const testP = response?.map((data) => {
				return {
					...data,
					dCreatedDate: new Date(data.dCreatedDate),
				}
			})
			setPackageList(testP)
		},
		onError: (error) => {
			setPackageList([])
		},
	})

	useEffect(() => {
		// const latestPackageArray = packageList.sort(
		//   (a, b) => b.dCreatedDate - a.dCreatedDate
		// );
		setFirstFour(packageList?.slice(0, 4))
	}, [packageList])

	useQuery('gameList', () => getGameList(), {
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setGameLength(response?.game?.length)
			const testG = response?.game?.map((data) => {
				return {
					...data,
					dCreatedDate: new Date(data.dCreatedDate),
				}
			})
			setGameList(testG)
		},
		onError: () => {
			setGameList([])
		},
	})

	useEffect(() => {
		// const latestGameArray = gameList.sort((a, b) => a.dCreatedDate - b.dCreatedDate)
		setFirstFourGame(gameList?.slice(0, 3))
	}, [gameList])

	const featureslider = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	const navigate = useNavigate()
	const handleNavigate = (id, packageName) => {
		navigate('/spel', { state: { id, packageName } })
	}

	useQuery(['profile'], () => getProfile(token), {
		enabled: !!token,
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			localStorage.setItem('profile', response?.sFullName)
		},
		onError: (response) => {
			toast.error(response?.response?.data?.message)
		},
	})

	const { mutate, isLoading: loginLoading } = useMutation(login, {
		onSuccess: (data) => {
			toast.success(<div>Inloggningen lyckades</div>)
			localStorage.setItem('Token', data?.data?.data?.authorization)
			setEmail('')
			setPassword('')
			setPrivateModal(true)
		},
		onError: (err) => {
			toast.error(<div>{err?.response?.data?.message}</div>)
		},
	})

	const handleChange = (e, type) => {
		if (type === 'email') {
			setEmail(e.target.value)
			mailRegex.test(e.target.value) ? setErrEmail(false) : setErrEmail(true)
		}
		if (type === 'password') {
			setPassword(e.target.value)
		}
	}
	const handleLogin = (e) => {
		e.preventDefault()
		if (email && password && !errEmail) {
			mutate({ email, password })
		}
	}
	const isLoggedInForm = localStorage.getItem('Token')

	function handleToggleEye() {
		setTogglePassword((togglePassword) => !togglePassword)
	}

	// this function used in axios intersepter
	setNav(navigate)

	const handleSignUp = (event) => {
		event.preventDefault()
		setSignUpModal(!signUpModal)
	}
	const toggleSignUp = () => setSignUpModal(!signUpModal)

	const handleEmailClick = () => {
		const email = 'support@thinkfirst.se'
		window.location.href = `mailto:${email}`
	}

	return (
		<>
			{/* <div className='loader'>
        <img src={loader} />
      </div> */}
			{loginLoading ? <Spinner /> : null}
			<Header isActive='home' />
			{fetchingBanner ? (
				<Spinner />
			) : (
				<>
					<div
						className='home-banner-sec'
						style={{
							backgroundImage: 'url(' + bannerImage + ')',
						}}>
						<div className='container'>
							<div className='row align-items-center'>
								<div className='col-lg-7 col-md-6'>
									<h1>Lär dig genom spel...</h1>
								</div>
								<div className='col-lg-5 col-md-6'>
									{!isLoggedInForm ? (
										<>
											<form onSubmit={handleLogin}>
												<h3>Logga in som elev</h3>
												<div className='form-grp'>
													<input
														value={email}
														onChange={(e) => handleChange(e, 'email')}
														type='email'
														required
														name='email'
														autoComplete='email'
														placeholder='E-post'
													/>
													<p className='error-msg'>{errEmail && 'Kontrollera mejladressen.'}</p>
												</div>
												<div className='form-grp'>
													<input
														value={password}
														onChange={(e) => handleChange(e, 'password')}
														type={togglePassword ? 'text' : 'password'}
														name='password'
														autoComplete='new-password'
														placeholder='Lösenord'
														required
													/>
													<span
														type='button'
														className={togglePassword ? 'active eye-icon' : 'eye-icon'}
														onClick={handleToggleEye}>
														{togglePassword ? <img src={eyeimg} /> : <img src={eyeimgactive} />}
													</span>
												</div>
												<div className='d-flex gap-3 justify-content-around'>
													<button
														disabled={loginLoading || !email || !password || errEmail}
														type='submit'>
														<span>Logga in</span>
													</button>
													<div>
														<button onClick={handleSignUp}>
															<span>SKAPA ELEVKONTO</span>
														</button>
														<span style={{ color: 'white' }}>OBS! Kräver skolkod</span>
													</div>
												</div>
												<div>
													<button
														className='bigBtn'
														style={{ width: '350px', marginTop: '5px' }}
														onClick={(e) => {
															e.preventDefault()
															e.stopPropagation()
															window.open(config?.Admin_Panel_URL, '_blank')
														}}>
														<span>LOGGA IN SOM LÂRARE / ADMIN</span>
													</button>
												</div>
												<div
													className='mt-3'
													style={{
														color: 'white',
														position: 'relative',
														zIndex: '11',
													}}>
													<h6>
														Vill du testa Run to Learn på din skola? Kontakta oss genom Kontakt-fliken.{' '}
														{/* <a
															href={config?.Admin_Panel_URL}
															target='_blank'
															rel='noreferrer'
															style={{ fontWeight: '800', color: 'white' }}>
															här
														</a>{' '} */}
														eller på{' '}
														<span
															style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic' }}
															onClick={handleEmailClick}>
															{' '}
															support@thinkfirst.se{' '}
														</span>
													</h6>
												</div>
											</form>
										</>
									) : (
										<div className='text-center play-now-btn-sec'>
											<a onClick={togglePrivateModal}>
												<img src={playnowbtn} />
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						className='features-sec'
						id='features-sec'>
						<div className='container'>
							<div className='text-center'>
								<h2 className='common-title'>Kom igång</h2>
							</div>
							{featureImages.length > 2 ? (
								<Slider
									{...featureslider}
									className='feature-slider'>
									{featureImages?.map((feature, index) => {
										return (
											<div>
												<div
													key={index}
													className={feature.endsWith('.mp4') ? 'feature-slide-video feature-slide' : 'feature-slide'}>
													{!feature.endsWith('.mp4') ? (
														<img src={config?.s3 + encodeURIComponent(feature)} />
													) : (
														<video
															style={{
																maxHeight: '100%',
																width: '100%',
															}}
															controls
															loop>
															<source
																src={config?.s3 + encodeURIComponent(feature)}
																type='video/mp4'
															/>
														</video>
													)}
												</div>
											</div>
										)
									})}
								</Slider>
							) : (
								<div className='d-flex justify-content-center flex-wrap mt-5'>
									{featureImages?.map((feature, index) => {
										return (
											<div>
												<div
													key={index}
													className={feature.endsWith('.mp4') ? 'single-image single-video' : 'single-image'}>
													{!feature.endsWith('.mp4') ? (
														<img src={config?.s3 + encodeURIComponent(feature)} />
													) : (
														<video
															controls
															loop>
															<source
																src={config?.s3 + encodeURIComponent(feature)}
																type='video/mp4'
															/>
														</video>
													)}
												</div>
											</div>
										)
									})}
								</div>
							)}
							<div className='feature-video-sec'>
								<Row style={{ marginTop: '25px' }}>
									<Col md='12'>
										<p>{featureDesc} </p>
									</Col>
									{youtubeVideo && (
										<Col md='12'>
											<iframe
												width='70%'
												height='350px'
												src={youtubeVideo}></iframe>
										</Col>
									)}
								</Row>
							</div>
						</div>
					</div>
					<div className='games-sec'>
						<div className='container'>
							<div className='text-center'>
								<h2 className='common-title'>Spel</h2>
							</div>
							<ul className='game-list'>
								{firstFourGame?.map((game, index) => (
									<li
										key={index}
										onClick={() => toggleGameModal(game?._id)}>
										<div className='game-list-header'>
											<img
												src={config?.s3 + game?.sGameIcon}
												alt='gameIcon'
											/>
											<h3>{game?.sGameName}</h3>
										</div>
										<p>
											{game?.sGameDescription?.slice(0, 150)}
											{game?.sGameDescription?.length >= 150 && '...'}
										</p>
										<div className='text-center'>
											<a
												href='javascript:void(0);'
												className='read-more-btn'>
												Läs mer...
											</a>
										</div>
									</li>
								))}
								<li>
									<h3>Kommer snart</h3>
								</li>
							</ul>
							<div className='text-center'>
								<a
									className='view-more-btn'
									href='/spel'>
									{gameLength > 3 && 'View More Games...'}
								</a>
							</div>
						</div>
					</div>
					<div className='features-sec package-details-sec'>
						<div className='container'>
							<div className='text-center'>
								<h2 className='common-title'>Paket</h2>
							</div>
							<ul className='packages-list'>
								{firstFour?.map((item, index) => {
									return (
										<li
											key={index}
											onClick={() => handleNavigate(item?._id, item?.sName)}>
											<h4>{item?.sName}</h4>
											<div className='packaimg-icon'>
												<img src={package1img} />
											</div>
											<ul className='package-info-list'>
												<li>1 Admin </li>
												<li>{item?.nAllowedMember} konton (lärarkonton+elevkonton)</li>
											</ul>
											<a className='view-details-btn'>Visa spelinfo</a>
											<a
												className='pricing-btn'
												href='#'>
												{item?.nPrice} kr/år ({item?.nAllowDays} dagar)
											</a>
											{/* <a href='/games' className='package-link'></a> */}
										</li>
									)
								})}
							</ul>
							<div className='text-center'>
								<a
									className='view-more-btn'
									href='/paket'>
									{packageLength > 3 && 'Se fler paket...'}
								</a>
							</div>
							<p className='query-text'>
								Vill du skräddarsy ditt paket eller har några frågor ? <a href='/kontakt'>Kontakta oss</a>
							</p>
						</div>
					</div>
				</>
			)}
			<Footer />
			{token && privateModal && (
				<PrivateGameModal
					modal={privateModal}
					togglemodal={togglePrivateModal}
				/>
			)}
			{gameInfoModal ? (
				<Gamemodal
					modal={gameInfoModal}
					togglemodal={toggleGameModal}
					gameId={gameId}
				/>
			) : (
				<></>
			)}
			<Modal
				isOpen={signUpModal}
				toggle={toggleSignUp}
				className='sign-up'>
				<SignUp
					toggleSignUp={toggleSignUp}
					signUpModal={signUpModal}
				/>
			</Modal>
		</>
	)
}
export default Home
