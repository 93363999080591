import React, { useState } from 'react'
import '../assets/sass/components/Footer.scss'
import footerlogo from '../assets/images/footer-logo.png'
import callicon from '../assets/images/call-icon.png'
import mailicon from '../assets/images/mail-icon.png'
import pinicon from '../assets/images/pin-icon.png'
import { useQuery } from 'react-query'
import axios from '../axios'

const Footer = (props) => {
	const [contactMobile, setContactMobile] = useState()
	const [contactAddress, setContactAddress] = useState()
	const [contactEmail, setContactEmail] = useState()

	useQuery('contact', () => axios.get(`web/cms/contactInfo`), {
		select: (data) => data?.data?.data[0],
		onSuccess: (response) => {
			setContactMobile(response?.sContactInfoMobile)
			setContactEmail(response?.sContactInfoEmail)
			setContactAddress(response?.sContactInfoAddress)
		},
		onError: () => {
			setContactMobile()
			setContactAddress()
			setContactEmail()
		},
	})

	return (
		<div>
			<div className='footer'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-3'>
							<img
								src={footerlogo}
								className='footerlogo'
							/>
						</div>
						<div className='col-md-4'>
							<h3>SNABBLÄNKAR</h3>
							<ul>
								<li>
									<a href='/policy'>INTEGRITETSPOLICY</a>
								</li>
								<li>
									<a href='/villkor'>VILLKOR</a>
								</li>
								<li>
									<a
										href='/faq'
										className='text-uppercase'>
										Hjälp
									</a>
								</li>
							</ul>
						</div>
						<div className='col-md-5'>
							<h3>KONTAKTA OSS</h3>
							<ul className='info-list'>
								<li>
									<img src={callicon} />
									<a href={`tel:${contactMobile}`}>{contactMobile}</a>
								</li>
								<li>
									<img src={pinicon} />
									{contactAddress}
								</li>
								<li>
									<img src={mailicon} />
									<a href={`mailto:${contactEmail}`}>{contactEmail}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
