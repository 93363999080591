import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, ModalFooter, Row, Spinner } from 'reactstrap'
import { getClasses, signUp } from '../query/login/login.mutation'
import { toast } from 'react-toastify'
import Select from 'react-select'

const SignUp = ({ toggleSignUp, signUpModal }) => {
	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		schoolCode: '',
		password: '',
		confirmPassword: '',
	})

	const [errors, setErrors] = useState({
		email: '',
		fullName: '',
		schoolCode: '',
		password: '',
		confirmPassword: '',
	})

	const [options, setOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState('')
	const [noClassFound, setNoClassFound] = useState(false)

	const validateForm = () => {
		const newErrors = {}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!formData.email.trim()) {
			newErrors.email = 'Email is required'
		} else if (!emailRegex.test(formData.email)) {
			newErrors.email = 'Invalid email address'
		}

		if (!formData.fullName.trim()) {
			newErrors.fullName = 'Full Name is required'
		}

		if (!formData.schoolCode.trim()) {
			newErrors.schoolCode = 'School code is required'
		}

		if (!formData.password.trim()) {
			newErrors.password = 'Password is required'
		}

		if (!formData.confirmPassword.trim()) {
			newErrors.confirmPassword = 'Confirm Password is required'
		}

		if (formData.password !== formData.confirmPassword) {
			newErrors.confirmPassword = 'Passwords do not match'
		}

		if (!selectedOption) {
			newErrors.selectedOption = 'Class selection is required'
		}

		setErrors(newErrors)
		return Object.keys(newErrors).length === 0
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		let newErrors = { ...errors }

		switch (name) {
			case 'email':
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
				newErrors.email = !value.trim() ? 'Email is required' : !emailRegex.test(value) ? 'Invalid email address' : ''
				break
			case 'fullName':
				newErrors.fullName = !value.trim() ? 'Full Name is required' : ''
				break
			case 'schoolCode':
				newErrors.schoolCode = !value.trim() ? 'School code is required' && setNoClassFound(false) : ''
				break
			case 'password':
				newErrors.password = !value.trim() ? 'Password is required' : ''
				break
			case 'confirmPassword':
				newErrors.confirmPassword = !value.trim()
					? 'Confirm Password is required'
					: formData.password !== value
					? 'Passwords do not match'
					: ''
				break
			default:
				break
		}

		setErrors(newErrors)
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleClassSelectChange = (selectedOption) => {
		setSelectedOption(selectedOption)
		setErrors({
			...errors,
			selectedOption: selectedOption ? '' : 'Class selection is required',
		})
	}

	const {
		isLoading: signUpLoading,
		isFetching: signUpFetching,
		mutate: signUpMutate,
	} = useMutation(signUp, {
		onSuccess: (response) => {
			response?.data?.message === 'Student registered successfully'
				? toast.success(<div>{response?.data?.message}</div>)
				: toast.error(<div>{response?.data?.message}</div>)

			toggleSignUp(!signUpModal)
		},
		onError: (response) => {
			toast.error(<div>{response?.response?.data?.message}</div>)
		},
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateForm()) {
			return
		}

		setErrors({
			email: '',
			fullName: '',
			schoolCode: '',
			password: '',
			confirmPassword: '',
			selectedOption,
		})

		signUpMutate({ data: formData, id: selectedOption })
	}

	const { isLoading, isFetching, mutate } = useMutation(getClasses, {
		onSuccess: (response) => {
			setOptions(response?.data?.data)
			setSelectedOption(response?.data?.data[0]?._id)
			setNoClassFound(!response?.data?.data?.length)
		},
	})

	const handleSchoolCode = (e) => {
		e.preventDefault()
		if (formData?.schoolCode) {
			mutate({ schoolCode: formData?.schoolCode })
		}
	}

	return (
		<div className='sign-up-container'>
			<form onSubmit={handleSubmit}>
				<h1
					className='text-center modal-title'
					style={{ fontFamily: 'PhotoWallSansShadow', fontSize: '60px', marginBottom: '20px' }}>
					skapa elevkonto
				</h1>
				<Row>
					<Col>
						<div className='mb-3'>
							<input
								placeholder='Full name / För-och efternamn'
								type='text'
								name='fullName'
								value={formData.fullName}
								onChange={handleChange}
							/>
							{errors.fullName && <div className='sign-up-error'>{errors.fullName}</div>}
						</div>
					</Col>
					<Col>
						<div className='mb-3'>
							<input
								placeholder='Email'
								type='email'
								name='email'
								value={formData.email}
								onChange={handleChange}
							/>
							{errors.email && <div className='sign-up-error'>{errors.email}</div>}
						</div>
					</Col>
				</Row>
				<div className='mb-3'>
					<div className='school-code'>
						<input
							type='text'
							name='schoolCode'
							value={formData.schoolCode}
							onChange={handleChange}
							maxLength={6}
							placeholder='School Code / Skolkod Skicka'
							disabled={!noClassFound && options?.length}
							className='school-code-input'
						/>
						<Button
							onClick={(e) => handleSchoolCode(e)}
							style={{ height: '47px', borderRadius: '50px', padding: '0 25px' }}
							disabled={formData?.schoolCode?.length !== 6}
							color={formData?.schoolCode?.length === 6 ? 'success' : ''}>
							{isLoading || isFetching ? <Spinner size='sm' /> : 'SUBMIT'}
						</Button>
					</div>
					{noClassFound && (
						<div className='sign-up-error'>
							<b>No class found, Check your school code</b>
						</div>
					)}
					{errors.schoolCode && <div className='sign-up-error'>{errors.schoolCode}</div>}
				</div>
				<div className='mb-3'>
					<Select
						className='class-select classname'
						placeholder='Class / Klass'
						options={options}
						getOptionLabel={(option) => option?.sClassName}
						getOptionValue={(option) => option?._id}
						onChange={handleClassSelectChange}
						value={selectedOption}
					/>

					{errors.selectedOption === 'Class selection is required' && <div className='sign-up-error'>{errors.selectedOption}</div>}
					<div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', marginTop: '7px' }}>
						<div>Om inte din klass finns i listan behöver din lärare skapa klassen innan du kan registrera dig.</div>
					</div>
				</div>

				<div className='mb-3'>
					<input
						placeholder='Password / Lösenord'
						type='password'
						name='password'
						value={formData.password}
						onChange={handleChange}
						autoComplete='new-password'
					/>
					{errors.password && <div className='sign-up-error'>{errors.password}</div>}
				</div>
				<div className='mb-3'>
					<input
						placeholder='Confirm password / Upprepa lösenord'
						type='password'
						name='confirmPassword'
						value={formData.confirmPassword}
						onChange={handleChange}
					/>
					{errors.confirmPassword && <div className='sign-up-error'>{errors.confirmPassword}</div>}
				</div>
				<div className='d-flex justify-content-between gap-2 signUp'>
					<Button
						onClick={() => {
							setFormData({
								fullName: '',
								email: '',
								schoolCode: '',
								password: '',
								confirmPassword: '',
							})
							setErrors({
								email: '',
								fullName: '',
								schoolCode: '',
								password: '',
								confirmPassword: '',
							})
							setNoClassFound(false)
							setOptions([])
							setSelectedOption('')
						}}>
						Reset
					</Button>
					<Button type='submit'>
						Registrera
						{(signUpLoading || signUpFetching) && <Spinner size='sm' />}
					</Button>
				</div>
			</form>
		</div>
	)
}

export default SignUp
