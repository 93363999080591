import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import CloseButton from '../assets/images/close-icon.png'
import lockimg from '../assets/images/lock.png'
import { useQuery } from 'react-query'
import { getPrivateGames } from '../query/private-games/privateGames.query'
import { toast } from 'react-toastify'
import config from '../config'
import Spinner from './Spinner'

export default function PrivateGameModal({ modal, togglemodal }) {
	const [lockedGames, setLockedGames] = useState([])
	const [unLockedGames, setUnLockedGames] = useState([])

	const token = localStorage.getItem('Token')

	const { isFetching } = useQuery('privateGamesList', () => getPrivateGames(token), {
		select: (data) => data.data.data,
		onSuccess: (response) => {
			setUnLockedGames(response?.aAvailableGame)
			setLockedGames(response?.aLockedGame)
		},
		onError: (err) => {
			if (err?.response?.status === 401) {
				togglemodal()
				toast.error(<div>Du har blivit utloggad. Vänligen logga in igen</div>)
				localStorage.removeItem('Token')
				localStorage.removeItem('profile')
			}
			setUnLockedGames([])
			setLockedGames([])
		},
	})

	const handleGamePopup = (gameId, url) => {
		togglemodal()
		window.open(
			`${url}?auth_token=${token}&iGameId=${gameId}`,
			`${gameId}`,
			`popup=1, toolbar=0,status=0,width=1216,height=684, top=${window.top.outerHeight / 2 + window.top.screenY - 684 / 2}, left=${
				window.top.outerWidth / 2 + window.top.screenX - 1216 / 2
			}`
		)
	}

	return (
		<Modal
			toggle={togglemodal}
			className='game-list-popup'
			id='game-list-popup'
			isOpen={modal}>
			<ModalBody>
				<div className='common-modal-sec'>
					<button
						onClick={togglemodal}
						className='common-close-btn'>
						<img src={CloseButton} />
					</button>
					<div className='games-sec my-games-sec'>
						<div className='container'>
							<div className='text-center'>
								<h2 className='common-title red'>SPEL</h2>
							</div>
							{isFetching ? (
								<div
									className='spinner-border text-dark game-loader'
									role='status'
								/>
							) : (
								<ul className='game-list'>
									{unLockedGames?.map((unlockedGame, index) => {
										return (
											<li
												key={index}
												onClick={() => handleGamePopup(unlockedGame?._id, unlockedGame?.sGameUrl)}>
												<div className='game-list-header'>
													<img src={config?.s3 + encodeURIComponent(unlockedGame?.sGameIcon)} />
													{/* <h3>{unlockedGame?.sGameName}</h3> */}
												</div>
											</li>
										)
									})}
									{lockedGames?.map((lockedGame, index) => {
										return (
											<li
												key={index}
												className='lock-game'>
												<div className='game-list-header'>
													<img src={config?.s3 + encodeURIComponent(lockedGame?.sGameIcon)} />
													{/* <h3>{lockedGame?.sGameName}</h3> */}
												</div>
												<div className='lock-img'>
													<img src={lockimg} />
												</div>
												<span>Your school hasn’t bought this game.</span>
											</li>
										)
									})}
									{unLockedGames?.length === 0 && lockedGames?.length === 0 ? (
										<div
											style={{
												textAlign: 'center',
												width: '100%',
												padding: '100px',
												fontSize: '20px',
											}}>
											No Games Available
										</div>
									) : (
										''
									)}
								</ul>
							)}
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}
