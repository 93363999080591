import axios from '../../axios'

export async function login(data) {
	const loginData = { sEmail: data.email?.toLowerCase(), sPassword: data.password }
	return await axios.post('auth/login', loginData)
}

export async function getProfile(token) {
	return await axios.get('profile', { headers: { authorization: token } })
}

export async function getClasses(schoolCode) {
	return await axios.get('/auth/get-classes/' + schoolCode?.schoolCode)
}
export async function signUp(data) {
	const payLoad = {
		sFullName: data?.data?.fullName,
		iClassId: data?.id,
		sEmail: data?.data?.email,
		sPassword: data?.data?.password,
		sUniqueSchoolCode: data?.data?.schoolCode,
	}
	return await axios.post('/auth/register', payLoad)
}
