import React from 'react'
import loader from '../assets/images/loader.gif'

function Spinner () {
  return (
    <div className='loader' >
      <img src={loader} alt='loader' />
    </div>
  )
}

export default Spinner