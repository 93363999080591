import React from 'react'

export default function UserIcon() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 14 14'
			fill='#fd5555'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.5435 12.4558C13.0102 11.6977 12.3604 11.0478 11.6022 10.5146C11.019 10.1064 10.4025 9.78976 9.7693 9.55648C11.119 8.65668 12.0021 7.12369 12.0021 5.39074C12.0021 2.63302 9.76097 0.391846 7.00324 0.391846C4.24552 0.391846 2.00435 2.63302 2.00435 5.39074C2.00435 7.12369 2.89582 8.65668 4.23719 9.55648C2.76252 10.098 1.42948 11.0811 0.454697 12.4558C0.24641 12.7558 0.313062 13.1723 0.612995 13.3806C0.729636 13.4639 0.86294 13.5056 0.996243 13.5056C1.20453 13.5056 1.41282 13.4056 1.54612 13.2223C3.66232 10.2147 7.82806 9.48983 10.8357 11.606C11.4689 12.0476 12.0105 12.5975 12.452 13.2223C12.6603 13.5223 13.0769 13.5972 13.3768 13.3806C13.6851 13.1723 13.7601 12.7558 13.5435 12.4558ZM3.33739 5.39074C3.33739 3.36619 4.97869 1.72488 7.00324 1.72488C9.0278 1.72488 10.6691 3.36619 10.6691 5.39074C10.6691 7.41529 9.0278 9.05659 7.00324 9.05659C4.97869 9.05659 3.33739 7.41529 3.33739 5.39074Z'
				fill='#fd5555'
			/>
		</svg>
	)
}
