import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home/Home'
import About from '../views/About/About'
import Packages from '../views/Packages/Packages'
import Games from '../views/Games/Games'
import Terms from '../views/Terms/Terms'
import Privacy from '../views/Privacy/Privacy'
import Spinner from '../components/Spinner'
import Profile from '../views/Profile'
const Contact = React.lazy(() => import('../views/Contact/Contact'))
const Help = React.lazy(() => import('../views/Help/Help'))

const Routers = () => {
	return (
		<Routes>
			<Route path='/'>
				<Route
					index
					element={<Home />}
				/>
			</Route>
			<Route
				path='kontakt'
				element={
					<Suspense fallback={<Spinner />}>
						<Contact />
					</Suspense>
				}
			/>
			<Route
				path='faq'
				element={
					<Suspense fallback={<Spinner />}>
						<Help />
					</Suspense>
				}
			/>
			<Route
				path='omoss'
				element={<About />}
			/>
			<Route
				path='paket'
				element={<Packages />}
			/>
			<Route
				path='spel'
				element={<Games />}
			/>
			<Route
				path='villkor'
				element={<Terms />}
			/>
			<Route
				path='policy'
				element={<Privacy />}
			/>
			<Route
				path='profile'
				element={<Profile />}
			/>
		</Routes>
	)
}

export default Routers
