import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/Home.scss'
import lockimg from '../../assets/images/lock.png'
import { useQuery, useQueryClient } from 'react-query'
import { getGameList } from '../../query/game/game.query'
import Gamemodal from '../../components/Gamemodal'
import { useLocation } from 'react-router-dom'
import { getPackageGames } from '../../query/package/packageGames.query'
import Spinner from '../../components/Spinner'
import config from '../../../src/config'

const Games = () => {
	const { state } = useLocation()
	const [id, setId] = useState(state?.id)
	const [packageName, setPackageName] = useState(state?.packageName)

	const [modal, setModal] = useState(false)
	const [gameId, setGameId] = useState()

	const togglemodal = (id) => {
		setGameId(id)
		return setModal(!modal)
	}
	window.history.replaceState({}, id)

	const [gameList, setGameList] = useState([])
	const [stateGameList, setStateGameList] = useState([])
	const [unLockedGames, setUnlockedGames] = useState([])
	const [lockedGames, setLockedGames] = useState([])

	const queryClient = useQueryClient()

	const { isFetching: fullListLoading } = useQuery(['gameList'], () => getGameList(), {
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setGameList(response?.game)
		},
		onError: () => {
			setGameList([])
		},
	})

	const getFilterdList = id !== undefined

	const { isLoading } = useQuery(['gameListById', id], () => getPackageGames(id), {
		enabled: getFilterdList,
		select: (data) => data?.data?.data,
		onSuccess: (response) => {
			setStateGameList(response)
		},
	})

	useEffect(() => {
		if (id) {
			const filterdData = gameList?.filter((data) => {
				return stateGameList?.find((d) => d?.iGameId === data?._id)
			})
			const filteredId = filterdData?.map((data) => {
				return data?._id
			})
			const matched = gameList.filter((obj1) => filteredId.some((obj2) => obj1._id === obj2))

			const unMatched = []
			for (let i = 0; i < gameList.length; i++) {
				const match = filteredId.find((item) => item === gameList[i]._id)
				if (!match) {
					unMatched.push(gameList[i])
				}
			}

			setUnlockedGames(matched)
			setLockedGames(unMatched)
		}
	}, [id, stateGameList, gameList])

	useEffect(() => {
		if (!id) {
			setUnlockedGames([])
			setLockedGames([])
		}
	}, [id])

	function handleRemoveFilter() {
		setUnlockedGames([])
		setLockedGames([])
		setId()
		setPackageName()
		queryClient.invalidateQueries('gameList')
	}

	return (
		<>
			<Header isActive='games' />
			<div className='games-sec my-games-sec'>
				<div className='container'>
					<div className='text-center'>
						<h2 className='common-title red'>SPEL</h2>
					</div>
					{!!id && (
						<>
							<div className='my-games-header-sec'>
								<h4>{packageName}</h4>
								<a
									className='view-more-btn'
									onClick={handleRemoveFilter}>
									Visa alla spel
								</a>
							</div>
							<p className='lock-info'>Note:- The "lock symbol" games are not available in this package.</p>
						</>
					)}
					{isLoading ? (
						<Spinner />
					) : (
						<ul className='game-list'>
							{!!unLockedGames?.length &&
								unLockedGames?.map((unlockedGame, index) => {
									return (
										<li
											key={index}
											onClick={() => togglemodal(unlockedGame?._id)}>
											<div className='game-list-header'>
												<img
													src={config?.s3 + unlockedGame?.sGameIcon}
													alt=''
												/>
												<h3>{unlockedGame?.sGameName}</h3>
											</div>
											<p>
												{unlockedGame?.sGameDescription?.slice(0, 150)}
												{unlockedGame?.sGameDescription?.length >= 150 && '...'}
											</p>
											<div className='text-center'>
												<a
													href='javascript:void(0);'
													className='read-more-btn'>
													Läs mer...
												</a>
											</div>
										</li>
									)
								})}
							{!!lockedGames?.length &&
								lockedGames?.map((lockedGame, index) => {
									return (
										<li
											key={index}
											className='lock-game'>
											<div className='game-list-header'>
												<img
													src={lockedGame?.sGameIcon}
													alt=''
												/>
												<h3>{lockedGame?.sGameName}</h3>
											</div>
											<p>
												{lockedGame?.sGameDescription?.slice(0, 150)}
												{lockedGame?.sGameDescription?.length >= 150 && '...'}
											</p>
											<div className='text-center'>
												<a
													href='javascript:void(0);'
													className='read-more-btn'>
													Läs mer...
												</a>
											</div>
											<div className='lock-img'>
												<img
													src={lockimg}
													alt='lockimg'
												/>
											</div>
										</li>
									)
								})}
							{fullListLoading ? (
								<Spinner />
							) : (
								!id &&
								gameList?.map((game, index) => {
									return (
										<li
											key={index}
											onClick={() => togglemodal(game?._id)}>
											<div className='game-list-header'>
												<img
													src={config?.s3 + game?.sGameIcon}
													alt=''
												/>
												<h3>{game?.sGameName}</h3>
											</div>
											<p>
												{game?.sGameDescription?.slice(0, 150)}
												{game?.sGameDescription?.length >= 150 && '...'}
											</p>
											<div className='text-center'>
												<a
													href='javascript:void(0);'
													className='read-more-btn'>
													Läs mer...
												</a>
											</div>
										</li>
									)
								})
							)}
							<li>
								<h3>Kommer snart</h3>
							</li>
						</ul>
					)}
				</div>
			</div>
			{modal ? (
				<Gamemodal
					modal={modal}
					togglemodal={togglemodal}
					gameId={gameId}
				/>
			) : (
				<></>
			)}
			<Footer />
		</>
	)
}
export default Games
