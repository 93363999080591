import React, { useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Modal, ModalBody } from 'reactstrap'
import logo from '../assets/images/logo.png'
import '../assets/sass/components/Header.scss'
import logoutImg from '../assets/images/logout.png'
import { logout } from '../query/logout/logout.mutation'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import CloseButton from '../assets/images/close-icon.png'
import Spinner from './Spinner'
import UserIcon from '../assets/images/User'

const Header = (props) => {
	const navigate = useNavigate()
	const [modal, setModal] = useState(false)
	function togglemodal() {
		setModal((modal) => !modal)
	}
	const handleClick = () => {
		navigate('/', { state: {} })
	}
	const [isOpen, setIsOpen] = React.useState(false)
	const token = localStorage.getItem('Token')
	// const profileName = localStorage.getItem('profile')
	const { mutate, isLoading } = useMutation(logout, {
		onSuccess: () => {
			toast.success(<div>Du är nu utloggad.</div>)
			localStorage.removeItem('Token')
			localStorage.removeItem('profile')
			navigate('/')
		},
		onError: (err) => {
			toast.error(<div>{err?.data?.message || err.response?.data?.message}</div>)
			localStorage.removeItem('Token')
			localStorage.removeItem('profile')
		},
	})
	const handleLogOut = () => {
		setModal(!modal)
		mutate({ token })
	}

	return (
		<div>
			{isLoading ? <Spinner /> : null}
			<Navbar
				expand='md'
				className={isOpen === true ? 'toggle-btn-open' : ''}>
				<div className='container'>
					<NavbarBrand onClick={handleClick}>
						<img
							src={logo}
							alt='logo'
						/>
					</NavbarBrand>
					<NavbarToggler
						onClick={() => {
							setIsOpen(!isOpen)
						}}>
						<span></span>
						<span></span>
						<span></span>
					</NavbarToggler>
					<Collapse
						isOpen={isOpen}
						navbar>
						<Nav
							className='ml-auto'
							navbar>
							<NavItem className={props?.isActive === 'home' ? 'active' : ''}>
								<NavLink to='/'>Hem</NavLink>
							</NavItem>
							<NavItem className={props?.isActive === 'games' ? 'active' : ''}>
								<NavLink to='/spel'>Spel</NavLink>
							</NavItem>
							{!token && (
								<NavItem className={props?.isActive === 'package' ? 'active' : ''}>
									<NavLink to='/paket'>Paket</NavLink>
								</NavItem>
							)}
							<NavItem className={props?.isActive === 'contactUs' ? 'active' : ''}>
								<NavLink to='/kontakt'>Kontakt</NavLink>
							</NavItem>
							<NavItem className={props?.isActive === 'help' ? 'active' : ''}>
								<NavLink to='/faq'>Hjälp</NavLink>
							</NavItem>
							<NavItem className={props?.isActive === 'AboutUs' ? 'active' : ''}>
								<NavLink to='/omoss'>Om oss</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</div>
				<div className='login-dropdown d-flex gap-3 align-items-start justify-content-center'>
					{token && (
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => navigate('/profile')}>
							<UserIcon />
						</div>
					)}
					{isLoading ? (
						<Spinner />
					) : (
						token && (
							<>
								<a
									onClick={togglemodal}
									className='logout-img'>
									<img src={logoutImg} />
									<p
										style={{ width: '60px', marginTop: '2px' }}
										className='tootltip-text'>
										Logga ut
									</p>
								</a>
							</>
						)
					)}
				</div>
			</Navbar>
			<Modal
				toggle={togglemodal}
				className='game-list-popup log-out-popup-sec'
				id='game-list-popup'
				isOpen={modal}>
				<ModalBody>
					<div className='common-modal-sec'>
						<div className='log-out-popup'>
							<h2>Är du säker på att du vill logga ut?</h2>
							<div className='d-flex justify-content-center logout-btns'>
								<button
									type='button'
									onClick={handleLogOut}>
									<span>Yes</span>
								</button>
								<button
									type='button'
									onClick={togglemodal}>
									<span>No</span>
								</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	)
}

Header.propTypes = {}

export default Header
