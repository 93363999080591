import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getAbout } from '../../query/about/about.query'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/About.scss'
import Spinner from '../../components/Spinner'
import config from '../../config'
import Slider from 'react-slick'

const About = () => {
	const [aboutImage, setAboutImage] = useState([])
	const [aboutDesc, setAboutDesc] = useState('')

	const featureSlider = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					autoplay: 5000,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					infinite: true,
					autoplay: 5000,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					autoplay: 5000,
				},
			},
		],
	}

	const { isLoading, isFetching } = useQuery('about', () => getAbout(), {
		select: (data) => data?.data?.data[0],
		onSuccess: (response) => {
			setAboutImage(response?.aAboutImage)
			setAboutDesc(response?.sAboutUsDescription)
		},
		onError: () => {
			setAboutImage()
			setAboutDesc()
		},
	})

	function convertNewlinesToBreaks(str) {
		return str.replace(/\n/g, '</br>')
	}

	const convertedStr = convertNewlinesToBreaks(aboutDesc)

	return (
		<>
			<Header isActive='AboutUs' />
			<div className='about-us-sec'>
				<div className='container'>
					<div className='text-center'>
						<h2 className='common-title red'>Om oss</h2>
					</div>
					<div className='row '>
						{isLoading || isFetching ? (
							<div className='w-100 d-flex justify-content-center'>
								<Spinner />
							</div>
						) : (
							<>
								<div className='col-md-6'>
									<div
										style={{ whiteSpace: 'pre-line' }}
										dangerouslySetInnerHTML={{ __html: convertedStr }}
									/>
								</div>
								<div className='offset-md-1 col-md-5'>
									<Slider
										{...featureSlider}
										className='feature-slider'>
										{aboutImage?.map((data) => {
											return (
												<div className='aboutSlider'>
													<img
														src={config?.s3 + encodeURIComponent(data)}
														alt='about-img'
														className='about-us-img'
													/>
												</div>
											)
										})}
									</Slider>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default About
