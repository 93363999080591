import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/index'
import '../src/assets/sass/common.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from 'react-query'
import ScrollToTop from './components/ScrollToTop'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
})

function App() {
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<ScrollToTop />
					<Routes />
					<ToastContainer
						position='top-right'
						autoClose={3000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme='colored'
					/>
				</BrowserRouter>
			</QueryClientProvider>
		</>
	)
}

export default App
