/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../assets/sass/common.scss";
import { useQuery } from "react-query";
import axios from "../../axios";
import Spinner from "../../components/Spinner";

const Privacy = () => {
  const [privacy, setPrivacy] = useState("");

  const { isFetching } = useQuery(
    "terms",
    () => axios.get("web/cms/privacyPolicy"),
    {
      select: (data) => data?.data?.data[0],
      onSuccess: (response) => {
        setPrivacy(response?.sDescriptionPrivacyPolicy);
      },
      onError: () => {
        setPrivacy("");
      },
    }
  );
  function convertNewlinesToBreaks(str) {
    return str.replace(/\n/g, "</br>");
  }

  const convertedStr = convertNewlinesToBreaks(privacy);

  return (
    <>
      <Header />
      <div className="terms-privacy-sec">
        <div className="container">
          <div className="text-center">
            <h2 className="common-title red">INTEGRITETSPOLICY</h2>
          </div>
          {isFetching ? (
            <div className="w-100 d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <div
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: convertedStr }}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Privacy;
